<template lang="pug">
.your-theme-latest(v-if="themes.user.length && getPossibleTemplates().length")
  .your-theme-row(
    v-for="theme in themes.user.slice(0, 2)"
    v-if="getTemplates(theme).length"
    :id="theme._id"
  )
    Skeleton.your-theme-row__block(v-if="$apollo.queries.templates.loading" key="skeleton" header)
    CollectionPreviews.your-theme-row__block(
      :name="theme.name"
      :to="!isEnabledMoreLink ? '' : getUserThemeRoute(theme.slug)"
      :templates="getTemplates(theme)"
      :useCaseMap="useCaseMap"
      :themeKit="getThemeKit(theme)"
      @navigateWithFilter="$emit('navigateWithFilter', $event)"
    )
</template>

<script>
  import { mapState } from 'vuex';
  import GET_CHOOSER_FILTERED_TEMPLATES from '@/graphql/GetChooserFilteredTemplates.gql';
  import CollectionPreviews from '../CollectionPreviews.vue';
  import childRouteMixin from '../../mixins/childRoute';
  import filterHelperMixin from '../../mixins/filterHelper';

  export default {
    name: 'YourThemes',
    components: {
      CollectionPreviews,
      Skeleton: () => import('@/components/TemplateChooser/components/Skeleton.vue'),
    },
    mixins: [childRouteMixin, filterHelperMixin],
    data: () => ({ templates: {} }),
    computed: {
      ...mapState(['accountType']),
      isEnabledMoreLink() {
        return !this.search;
      },
      limit() {
        return 4;
      },
    },
    apollo: {
      templates: {
        query: GET_CHOOSER_FILTERED_TEMPLATES,
        variables() {
          const filter = {
            ...this.filter,
            search: this.search,
            themeKit: this.themes?.user?.map?.(({ sourceTheme }) => sourceTheme),
          };
          return { filter, type: this.accountType };
        },
        result() {
          this.$emit('loaded', this);
        },
      },
    },
    methods: {
      getPossibleTemplates() {
        const possibleTemplates = this.themes.user
          .slice(0, 2)
          .map((theme) => this.getAllTemplatesForTheme(theme))
          .flat();

        if (this.search) {
          this.$nextTick(() => {
            this.$emit('templateCount', {
              type: 'themes',
              count: Number(possibleTemplates.length),
            });
          });
        }

        return possibleTemplates;
      },
      getAllTemplatesForTheme(theme) {
        const templates = this.templates.byTheme?.[theme.sourceTheme] ?? [];

        if (this.search) {
          return templates.filter(({ _id: possibleId }) =>
            this.templates?.searchResults?.some?.(({ _id }) => possibleId === _id),
          );
        }

        return templates;
      },
      getTemplates(theme) {
        return this.getAllTemplatesForTheme(theme)?.slice?.(0, this.limit);
      },
      getUserThemeRoute(slug) {
        return {
          name: 'your-themes-templates',
          params: { slug },
        };
      },
      getThemeKit(theme) {
        const themeKit = { ...theme.themeKit };
        themeKit.id = themeKit?.id || theme._id;
        return themeKit;
      },
    },
  };
</script>

<style lang="sass">
  .your-theme-row__block
    margin-bottom: 3.75rem
</style>
